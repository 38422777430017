<template>
  <div>
    <Heard />

    <div class="container-fluid login-box">
      <div class="container write-box">
        <el-card>
          <div class="chooseitem">
            <router-link to="/login" class="linebottom btn-title"
              >密码登录</router-link
            >
            <router-link class="btn-title" to="/login_phone"
              >短信登录</router-link
            >
          </div>
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="mobile">
              <el-input
                v-model="ruleForm.mobile"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="ispwd">
              <el-input
                type="password"
                show-password
                v-model="ruleForm.ispwd"
                placeholder="请输入密码"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <div class="loginbtn" @click="submitForm('ruleForm')">登录</div>
            </el-form-item>
          </el-form>
          <div class="samtag">
            <router-link to="/register" class="nuxtlink">免费注册</router-link>
            <router-link to="/forget" class="nuxtlink">忘记密码</router-link>
          </div>
        </el-card>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { api_authLogin } from "@/apis/apis";
import { setUser, setfile } from "@/plugins/auth.js";

import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Heard,
    Footer,
  },
  data() {
    //手机号
    var checkmobile = (rule, value, callback) => {
      var re = /^1\d{10}$/;
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (!re.test(value)) {
          callback(new Error("请输入正确的手机号"));
        }
        callback();
      }
    };
    //密码
    var validateispwd = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_]{6,12}$/;

      if (!value) {
        return callback(new Error("请输入密码"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入6-12位字母数字结合的密码"));
        }
        callback();
      }
    };

    return {
      ruleForm: {
        ispwd: "",
        mobile: "",
      },
      rules: {
        ispwd: [{ validator: validateispwd, trigger: "blur" }],
        mobile: [{ validator: checkmobile, trigger: "blur" }],
      },
    };
  },
  methods: {
    // 验证表单并登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //         async fetch ({ app, store, params }) {
          //   let { res } = app.$axios.get('/token');
          //   store.commit('setToken', res.data.token);
          // }

          api_authLogin({
            mobile: this.ruleForm.mobile,
            passwd: this.ruleForm.ispwd,
            loginType: 0, // 0: 账号密码登  ，1：短信验证码登录 2微信登录
          }).then((res) => {
            console.log(res);
            if (res.data.status === true) {
              console.log(res.data.data.user);
              setUser(
                res.data.data.token,
                res.data.data.user.id,
                res.data.data.user.avatar,
                res.data.data.user.mobile,
                res.data.data.user.nickName
              );
              setfile(res.data.data.user.filePathPc);

              // this.$store.commit("userstore/setUserInfo", res.data.data);
              // window.localStorage.setItem(
              //   "storeState",
              //   JSON.stringify(res.data.data)
              // );
              this.$router.push("/");
            } else {
              this.$message({
                showClose: true,
                message: "密码错误，请重新输入",
                type: "error",
                offset: 60,
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  // created() {
  //   console.log(window);
  // },
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
}
/* 版心 */
.container {
  width: 1200px;
  margin: 0 auto;
}
.login-box {
  height: 600px;
  background: url(~@/assets/image/loginbanner.png) no-repeat center;
  // background-size: 100% 100%;
  .write-box {
    position: relative;
    /deep/ .el-card__body {
      padding: 0;
    }
    .el-card {
      width: 360px;
      height: 400px;
      position: absolute;
      top: 100px;
      right: 100px;
      padding: 30px 40px 34px;
      .chooseitem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 34px;

        .btn-title {
          line-height: 30px;
          color: #7b7b7b;
          font-size: 20px;
          cursor: pointer;
        }
        .linebottom {
          border-bottom: 2px solid #2d69f9;
          color: #000;
          font-weight: bold;
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
      .el-input {
        margin-bottom: 20px;
      }
      /deep/ .el-form-item__error {
        top: 63%;
      }
      .loginbtn {
        height: 44px;
        background: #2d69f9;
        opacity: 1;
        color: #fff;
        text-align: center;
        line-height: 44px;
        letter-spacing: 4px;
        font-size: 16px;
      }
      .samtag {
        margin-top: 16px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .nuxtlink {
          color: #225592;
        }
      }
      .jumpwxlogin {
        display: block;
        width: 160px;
        height: 40px;
        background: #f8f8fa;
        border-radius: 100px;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        font-size: 12px;
        color: #7b7b7b;
      }
    }
  }
}
</style>